<template>
  <section id="content">
    <div class="wrapper wrapper-content animated fadeInRight">

      Storage page
      
      <FileUpload></FileUpload>
    </div>
  </section>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import FileUpload from '../panels/FileUpload.vue';

@Component({
  components: { FileUpload }
})
export default class Storage extends mixins(GenericPage) {
  
}
</script>