<template>
  <div>
    <form enctype="multipart/form-data">
        <h1>Upload images</h1>
        <div class="dropbox">
          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            :accept="acceptedTypes" class="input-file">
            <p v-if="isInitial">
              Drag your file(s) here to begin<br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading {{ fileCount }} files...
            </p>
        </div>
        <div class="dropzone">
          
        </div>
      </form>
      
    </div>
</template>

<style>
  .dropbox {
    outline: 1px dashed #666666; /* the dash box */
    outline-offset: -10px;
    background: #E8BCFF;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #C89CFF; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .dropzone {
    min-height: 150px;
    outline: 1px dashed rgba(0, 0, 0, 0.3);
    background: white;
    padding: 20px 20px;
    outline-offset: -10px;
    border: 0;
  }

  .dropzone .dz-preview {
    position: relative;
    display: block;
    margin: 4px;
    min-height: auto;
  }

  .dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 20px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd);
  }

  .dropzone .dz-preview .dz-image {
    overflow: hidden;
    width: auto;
    height: 30px;
    position: relative;
    display: block;
    z-index: 10;
  }
  
  .dropzone .dz-preview .dz-details {
    position: absolute;
    top: 0px;
    z-index: 1000;
    line-height: 30px;
  }

  .dropzone .dz-preview .dz-details .dz-size {
    display:inline-block;
  }

  .dropzone .dz-preview .dz-details .dz-filename {
    display:inline-block;
  }

  .dropzone .dz-preview .dz-progress {
    bottom: 4px;
    top: auto;
    margin-top: 0px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import axios, { AxiosResponse } from '@fwk-node-modules/axios';

import dropzone from "dropzone";
import '@public/css/dropzone.css';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

@Component({
  components: {},
  computed: {}
})
export default class FileUpload extends Vue {

  uploadedFiles:Array<any> = [];
  uploadError:null = null;
  currentStatus:number = STATUS_INITIAL;
  uploadFieldName:string = "files";
  acceptedTypes:string = "*/*";
  
  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  };

  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  };
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  };
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  };

  reset() {
    // reset form to initial state
    this.currentStatus = STATUS_INITIAL;
    this.uploadedFiles = [];
    this.uploadError = null;
  };

  save(formData:any) {
    // upload data to the server
    this.currentStatus = STATUS_SAVING;

    this.upload(formData)
      .then((files:any) => {
        this.uploadedFiles = [].concat(files);
        this.currentStatus = STATUS_SUCCESS;
      })
      .catch((err:any) => {
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
  };  

  upload(formData:any) {
    const url = '/api/storage/upload';
    return axios.post(url, formData)
        // get data
        .then((response:AxiosResponse<any>) => {
          console.log(response.data);
          return response.data;
        })
  }

  filesChange(fieldName:string, fileList:Array<any>) {
    // handle file changes
    const formData = new FormData();

    if (!fileList.length) return;

    // append the files to FormData
    Array
      .from(Array(fileList.length).keys())
      .map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

    // save it
    this.save(formData);
  };

  mounted() {
    var myDropzone = new dropzone(".dropzone", { 
      url: "/file/post",
      autoProcessQueue: false,
      addRemoveLinks: true
    });
    this.reset();
  };

}
</script>